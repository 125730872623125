import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Dispatch, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { initEnvironment } from "app/modules/environment";
import * as fetchUtils from "app/utils/fetchUtils";
import { createStandaloneToast } from "@chakra-ui/react";

import { Authed } from "app/types/common";
import captureException from "app/utils/captureException";
import { deleteToken } from "app/config/firebase";

const COOKIE_PATH = "accessToken";

const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
const RECEIVE_AUTHED_USER = "RECEIVE_AUTHED_USER";
const RESET_AUTHED = "RESET_AUTHED";
const CHECKED_AUTHED = "CHECKED_AUTHED";
const VERIFY_EMAIL = "VERIFY_EMAIL";

interface ReceiveAccessTokenAction {
    type: typeof RECEIVE_ACCESS_TOKEN;
    accessToken: string | null;
    authJWT: any;
}
interface ReceiveAuthedUserAction {
    type: typeof RECEIVE_AUTHED_USER;
    user: any;
    isAuthChecked: boolean;
}
interface CheckedAuthedAction {
    type: typeof CHECKED_AUTHED;
    isAuthChecked: boolean;
}
interface VerifyEmailAction {
    type: typeof VERIFY_EMAIL;
    isEmailVerified: boolean;
}
type AuthedActionTypes = ReceiveAccessTokenAction | ReceiveAuthedUserAction | CheckedAuthedAction | VerifyEmailAction;

const initialState: Authed = {
    accessToken: null,
    authJWT: null,
    user: {},
    isAuthChecked: false,
    isEmailVerified: false,
};

export default function authed(state: Authed = initialState, action: AuthedActionTypes): Authed {
    switch (action.type) {
        case RECEIVE_ACCESS_TOKEN: {
            const { accessToken } = action;
            return {
                ...state,
                accessToken,
                authJWT: accessToken && jwtDecode(accessToken),
            };
        }
        case RECEIVE_AUTHED_USER:
            return {
                ...state,
                user: action.user,
            };
        case CHECKED_AUTHED:
            return {
                ...state,
                isAuthChecked: action.isAuthChecked,
            };
        case VERIFY_EMAIL:
            return {
                ...state,
                isEmailVerified: action.isEmailVerified,
            };
        default:
            return state;
    }
}

function resetAuthed() {
    return {
        type: RESET_AUTHED,
    };
}

function receiveAccessToken(accessToken: string) {
    return {
        type: RECEIVE_ACCESS_TOKEN,
        accessToken,
    };
}

function receiveAuthedUser(user: any) {
    return {
        type: RECEIVE_AUTHED_USER,
        user,
    };
}

function receiveAuthedUserPre(accessToken: string, user: any) {
    return (dispatch: Dispatch) => {
        dispatch(receiveAuthedUser(user));
        dispatch(receiveAccessToken(accessToken));
        return dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
    };
}

function fetchAuthedUser(accessToken: string) {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
        fetchUtils
            .getJSON("/api/me")
            .then((u) => {
                dispatch(receiveAuthedUserPre(accessToken, u));
            })
            .catch((err) => {
                dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
            });
}

function authUser(accessToken: string) {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => dispatch(fetchAuthedUser(accessToken));
}

export function initAuth() {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        const accessToken = Cookies.get(COOKIE_PATH);
        if (accessToken) {
            return dispatch(authUser(accessToken));
        }
        return dispatch({ type: CHECKED_AUTHED, isAuthChecked: true });
    };
}

function mapChangePasswordFormData(formData: any) {
    return {
        oldPassword: formData.oldPassword,
        password: formData.password,
    };
}

export function changePassword(formData: any) {
    return (dispatch: Dispatch) => {
        fetchUtils
            .postJSON<{ oldPassword: string; password: string }>(
                "/api/password/change",
                mapChangePasswordFormData(formData)
            )
            .then((data) => {
                /* if (data.Status !== "Success") {
                    toast({
                        title: "An error occurred.",
                        description: "Couldn't able to change password...",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Password Changed",
                        description: "Your password has been successfully updated...",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                    });
                } */
                return data;
            })
            .catch((ex) => {
                /* toast({
                    title: "An error occurred.",
                    description: "Couldn't able to change password...",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                }); */
            });
    };
}

export function verifyEmail(data: any) {
    return (dispatch: Dispatch) => {
        fetchUtils
            .postJSON("/api/user/verify/email", data)
            .then((data) => {
                dispatch({
                    type: VERIFY_EMAIL,
                    isEmailVerified: data.Status === "Success",
                });
            })
            .catch((ex) => {
                fetchUtils.handleError("Login failed", ex);
            });
    };
}

export function refreshToken() {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        fetchUtils
            .postJSON("/auth/refresh-token", {})
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .catch((ex) => {
                console.log("RefreshToken failed", ex);
            });
    };
}

export function handleAuth(token: string) {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        fetchUtils.setAuthCookie(token);
        dispatch(authUser(token));
    };
}

function mapLoginFormData(formData: LoginInput) {
    return {
        email: formData.email,
        password: formData.password,
    };
}

interface LoginInput {
    email: string;
    password: string;
}

export function loginUser(formData: LoginInput) {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
        fetchUtils
            .postJSON<{ email: string; password: string }>("/auth/login", mapLoginFormData(formData), {
                skipAuthRefresh: true,
            })
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return dispatch(authUser(data.token));
            })
            .catch((ex) => {
                fetchUtils.handleError("Login failed", ex);
            });
}

function mapSignupFormData(formData: any) {
    return {
        name: formData.name,
        email: formData.email,
        password: formData.password,
    };
}

interface SignupInput {
    name: string;
    email: string;
    password: string;
}

export function signupUser(formData: SignupInput) {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        fetchUtils
            .postJSON("/auth/signup", mapSignupFormData(formData))
            .then((data) => {
                fetchUtils.setAuthCookie(data.token);
                return data;
            })
            .then((data) => dispatch(authUser(data.token)))
            .catch((ex) => {
                fetchUtils.handleError("Something went wrong!", ex);
            });
    };
}

export const disablePushNotification = () => {
    try {
        ["pushConfig"].forEach((k) => {
            localStorage.removeItem(k);
        });
    } catch (e) {
        captureException(e);
    }
    return deleteToken();
};

export function logoutUser() {
    const toast = createStandaloneToast();

    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        Cookies.remove(COOKIE_PATH, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        disablePushNotification();
        dispatch(resetAuthed());
        dispatch(initEnvironment());
        dispatch(initAuth());
        toast({
            title: "See you soon!",
            description: "You have logged out successfully...",
            status: "info",
            duration: 9000,
            isClosable: true,
        });
    };
}
