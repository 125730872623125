import { Dispatch } from "redux";

const CHANGE_IS_MOBILE = "CHANGE_IS_MOBILE";
const CHANGE_WIDTH_AND_HEIGHT = "CHANGE_WIDTH_AND_HEIGHT";

interface ChangeIsMobileAction {
    type: typeof CHANGE_IS_MOBILE;
    isMobile: boolean;
}
interface ChangeWidthAndHeightAction {
    type: typeof CHANGE_WIDTH_AND_HEIGHT;
    height: number;
    width: number;
}
interface EnvState {
    isMobile: boolean;
    height: number | null;
    width: number | null;
}
type EnvActionTypes = ChangeIsMobileAction | ChangeWidthAndHeightAction;

const initialState: EnvState = {
    isMobile: false,
    height: null,
    width: null,
};

export default function environment(state: EnvState = initialState, action: EnvActionTypes) {
    switch (action.type) {
        case CHANGE_IS_MOBILE:
            return {
                ...state,
                isMobile: action.isMobile,
            };

        case CHANGE_WIDTH_AND_HEIGHT:
            return {
                ...state,
                height: action.height,
                width: action.width,
            };
        default:
            return state;
    }
}

function changeIsMobile(isMobile: boolean) {
    return {
        type: CHANGE_IS_MOBILE,
        isMobile,
    };
}

export function changeWidthAndHeight(height: number, width: number) {
    return {
        type: CHANGE_WIDTH_AND_HEIGHT,
        height,
        width,
    };
}

export function initEnvironment() {
    return (dispatch: Dispatch) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        dispatch(changeIsMobile(isMobile));
        dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));

        window.onresize = () => {
            dispatch(changeWidthAndHeight(window.innerHeight, window.innerWidth));
        };
    };
}
