// import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";

import "./index.css";

// The styles for prism need to be loaded once
import "prismjs/themes/prism-okaidia.css";

import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";

import App from "app/App";
import configureStore from "app/store/configureStore";

import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import "react-datepicker/dist/react-datepicker.css";

const store = configureStore();

const gbTheme = extendTheme({
    fonts: {
        body: "Inter, system-ui, sans-serif",
        heading: "Inter, Georgia, serif",
    },
    colors: {
        brand: {
            50: "#e6f3ff",
            100: "#cce6ff",
            200: "#99ceff",
            300: "#66b5ff",
            400: "#339cff",
            500: "#0083FF",
            600: "#0069cc",
            700: "#004f99",
            800: "#003566",
            900: "#001a33",
        },
        gray: {
            50: "#f2f2f2",
            100: "#e6e6e6",
            200: "#cccccc",
            300: "#b3b3b3",
            400: "#999999",
            500: "#808080",
            600: "#666666",
            700: "#4d4d4d",
            800: "#333333",
            900: "#1a1a1a",
        },
    },
    sizes: {
        "2md": "40rem",
    },
    styles: {
        global: {
            //The emerging W3C standard that is currently Firefox-only
            "*": { scrollbarWidth: "thin", scrollbarColor: "#00000033 #ffffff1a" },
            //Works on Chrome/Edge/Safari
            "*::-webkit-scrollbar": { width: "6px", height: "6px" },
            "*::-webkit-scrollbar-track": { background: "#ffffff1a" },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
                transition: "all 200ms ease",
            },
            "*:hover::-webkit-scrollbar-thumb": {
                backgroundColor: "#00000033",
            },

            //https://github.com/WICG/focus-visible
            ".js-focus-visible :focus:not([data-focus-visible-added])": {
                outline: "none",
                boxShadow: "none",
            },
            ".chakra-collapse": {
                overflow: "inherit !important",
            },
        },
    },
    components: {
        Popover: {
            variants: {
                responsive: {
                    popper: {
                        maxWidth: "unset",
                        width: "unset",
                    },
                },
            },
        },
    },
});

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

function importBuildTarget() {
    if (process.env.REACT_APP_BUILD_TARGET === "zoho") {
        return import("./app/IntegApp");
    } else {
        return import("./app/App");
    }
}

importBuildTarget().then(({ default: Environment }) =>
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <ChakraProvider theme={gbTheme}>
                    <CSSReset />
                    <Environment />
                </ChakraProvider>
            </Router>
        </Provider>,
        document.getElementById("root")
    )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
