import captureException from "app/utils/captureException";
import firebase from "firebase/app";
import "firebase/messaging";

const vapidKey = "BHLzc_3DqHVw_8OrlL22E0LNI7XhhuVlczSxqogdGNRwRWMUsGzzQ7N8sHNyrwPsKp93fT4RxYpmZeBd_qz_qIo";
const firebaseConfig = {
    apiKey: "AIzaSyAejxTTSoz3T4JDEShoCfZqlnMn09ek8P0",
    authDomain: "gallabox-cloud.firebaseapp.com",
    projectId: "gallabox-cloud",
    storageBucket: "gallabox-cloud.appspot.com",
    messagingSenderId: "922442006911",
    appId: "1:922442006911:web:4e591f6a3280c9b61d5a4a",
    measurementId: "G-8P23G7S9XG",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const getToken = (): Promise<string> => {
    if (!firebase.messaging.isSupported()) return Promise.resolve("");
    const messaging = firebase.messaging();
    return messaging.getToken({ vapidKey });
};
export const deleteToken = (): Promise<boolean> => {
    if (!firebase.messaging.isSupported()) return Promise.resolve(false);
    const messaging = firebase.messaging();
    return messaging.deleteToken();
};
export const onMessageListener = (callBack: firebase.NextFn<any>): firebase.Unsubscribe => {
    if (!firebase.messaging.isSupported()) return () => 0;
    const messaging = firebase.messaging();
    return messaging.onMessage(callBack, (err) => {
        captureException(err);
    });
};

export default firebaseApp;
