import { createStandaloneToast } from "@chakra-ui/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { compile } from "path-to-regexp";

export const COOKIE_PATH = "accessToken";

const gbAPI = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    validateStatus: function (status) {
        return status >= 200 && status < 300;
    },
});

export function setAuthCookie(token: string): void {
    const authJWT = jwtDecode<AuthJWT>(token);
    const tokenExpiryDate = authJWT && new Date(authJWT.exp * 1000);
    const oneDay = new Date(new Date().getTime() + 60 * 24 * 60 * 1000);
    Cookies.set(COOKIE_PATH, token, {
        expires: tokenExpiryDate ?? oneDay,
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
}

// Function that will be called to refresh authorization
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const refreshAuthLogic = (failedRequest: any) =>
    gbAPI.post("/auth/refresh-token").then((tokenRefreshResponse) => {
        setAuthCookie(tokenRefreshResponse.data.token);
        failedRequest.response.config.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.token;
        return Promise.resolve();
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(gbAPI, refreshAuthLogic);

class HttpError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(message?: string, response?: any) {
        super(message);
        this.response = response;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export function handleError(title: string, ex: HttpError) {
    const toast = createStandaloneToast();
    toast({
        title,
        description: ex?.response?.data.Message || "Please try again...",
        status: "error",
        duration: 9000,
        isClosable: true,
    });
}
export function setHeaders() {
    const accessToken = Cookies.get(COOKIE_PATH);
    const h: HeadersInit = {};
    if (accessToken) {
        h.Authorization = `Bearer ${accessToken}`;
    }
    return {
        headers: h,
    };
}

export function setRequestConfig(): AxiosRequestConfig {
    return { withCredentials: true };
}

interface AuthJWT {
    email: string;
    name: string;
    id: string;
    iat: number;
    exp: number;
}

const handleAxiosError = (err: AxiosError) => {
    const error = err.response?.data.error;
    const message = err.response?.data.message;
    if (error ?? message) {
        throw new Error(error ?? message);
    }
    throw err;
};

export function getJSON(url: string, config?: AxiosRequestConfig, isAuth: boolean = true) {
    const requestConfig: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(url, { ...(config ?? {}), ...requestConfig })
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function postJSON<T extends Record<string, any>>(url: string, payload: T, config?: AxiosRequestConfig) {
    const headers: AxiosRequestConfig = setHeaders();
    return gbAPI
        .post(url, payload, { ...(config ?? {}), ...headers })
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function patchJSON<T>(url: string, payload?: T | Partial<T>): Promise<T> {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .patch(url, payload, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function putJSON<T>(url: string, payload: T) {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .put(url, payload, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function deleteJSON<T>(url: string): Promise<T> {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .delete(url, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function getCompliedUrl(url: string) {
    const accessToken = Cookies.get(COOKIE_PATH);
    let authJWT: Record<string, unknown> | null = null;
    if (accessToken) {
        try {
            authJWT = jwtDecode(accessToken);
        } catch (ex) {}
    }
    const toPath = compile(url);
    return authJWT ? toPath({ ...authJWT }) : url;
}

export function fetcherComplied(url: string) {
    const accessToken = Cookies.get(COOKIE_PATH);
    let authJWT: Record<string, unknown> | null = null;
    if (accessToken) {
        try {
            authJWT = jwtDecode(accessToken);
        } catch (ex) {}
    }
    const toPath = compile(url);
    const compiledUrl = authJWT ? toPath({ ...authJWT }) : url;
    const config: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(compiledUrl, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function fetcher(url: string) {
    const config: AxiosRequestConfig = setRequestConfig();
    return gbAPI
        .get(url, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export function patchFetcher<T>(url: string, payload?: T) {
    const config: AxiosRequestConfig = setHeaders();
    return gbAPI
        .patch(url, payload, config)
        .then((response) => response.data)
        .catch(handleAxiosError);
}

export async function createFile(
    url: string,
    defaultFileName: string = `${new Date().toLocaleString()}`,
    defaultType: string = "image/jpeg"
): Promise<File> {
    const fileName = url.substr(url.lastIndexOf("/") + 1);
    const response = await axios.get(url, { responseType: "blob" });
    const contentType = response.headers["content-type"] ?? defaultType;
    const blob = response.data as Blob;
    const file = new File([blob], defaultFileName ?? fileName, { type: contentType });
    return file;
}

export async function getUrlContentType(url: string): Promise<string | null> {
    const req = await axios.head(`${url}?t=${new Date().getTime()}`);
    return req.headers["content-type"] ?? null;
}

export function mapQueryParams(params: Record<string, string | number | boolean>): string {
    return Object.entries(params)
        .map(([key, value]) => (value ? `${key}=${value}` : ""))
        .filter(Boolean)
        .join("&");
}
